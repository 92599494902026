import * as React from 'react'
import { Link } from 'gatsby'

const ButtonItem = ({ children, link, isSelected }) => (
  <div className='inline-block px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-blue-500 hover:bg-white mt-4 mb-1 lg:mt-4'>
    <Link
      className={isSelected ? 'font-bold' : ''}
      to={link}
    >
      {children}
    </Link>
  </div>
)

export default ButtonItem
