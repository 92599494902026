import * as React from 'react'

const H1 = props => <h1 {...props} className='text-4xl mb-4 mt-8'>{props.children}</h1>
const H2 = props => <h2 {...props} className='text-3xl mb-3 mt-6'>{props.children}</h2>
const H3 = props => <h3 {...props} className='text-2xl mb-2 mt-4'>{props.children}</h3>
const P = props => <p {...props} className='mb-2' />
const UL = props => <ul {...props} className='list-disc list-inside' />
const A = props => <a {...props} href={props.href} className='text-blue-700 hover:text-blue-500' />

export { H1, H2, H3, P, UL, A }
