// domain option are "mahajrya" (default) and "mie"

const getDomain = () => {
  return process.env.GATSBY_DOMAIN || 'mahajrya'
}

const isMahajryaDomain = () => {
  return getDomain() === 'mahajrya'
}

export { getDomain, isMahajryaDomain }
