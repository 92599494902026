import * as React from 'react'
import PropTypes from 'prop-types'
import { MDXProvider } from '@mdx-js/react'
import * as DesignSystem from '../design-system'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import { getDomain } from '../utils/getDomain'
import { BackTeacher } from './ui/back-home'

import Header from './header'
import Sidebar from './sidebar'

const Layout = ({ children, pageContext: { frontmatter: { path = '/', seoTitle = '' } = {} } }) => {
  const { title } = useSiteMetadata()

  return (
    <>
      <Header siteTitle={title} seoTitle={seoTitle} currentPath={path} />
      <div className='container relative mx-auto px-4 lg:pr-72 min-h-screen'>
        <main className='pr-4'>
          {path.match(/enseignants\/.+/) && <BackTeacher />}
          <MDXProvider
            components={{
              h1: DesignSystem.H1,
              h2: DesignSystem.H2,
              h3: DesignSystem.H3,
              p: DesignSystem.P,
              ul: DesignSystem.UL,
              a: DesignSystem.A
            }}
          >
            <div className='mt-8 mb-2'>{children}</div>
          </MDXProvider>
        </main>
        <Sidebar />
        <footer className='text-sm mb-2 mt-10'>
          © {new Date().getFullYear()} Association du Mahajrya Suisse
          <br />
          <i style={{ color: '#fff' }}>{getDomain()}</i>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
