exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-contact-index-mdx": () => import("./../../../src/pages/contact/index.mdx" /* webpackChunkName: "component---src-pages-contact-index-mdx" */),
  "component---src-pages-contact-merci-mdx": () => import("./../../../src/pages/contact/merci.mdx" /* webpackChunkName: "component---src-pages-contact-merci-mdx" */),
  "component---src-pages-enseignants-achalashraddha-mdx": () => import("./../../../src/pages/enseignants/achalashraddha.mdx" /* webpackChunkName: "component---src-pages-enseignants-achalashraddha-mdx" */),
  "component---src-pages-enseignants-dharmikaprasad-mdx": () => import("./../../../src/pages/enseignants/dharmikaprasad.mdx" /* webpackChunkName: "component---src-pages-enseignants-dharmikaprasad-mdx" */),
  "component---src-pages-enseignants-emma-mdx": () => import("./../../../src/pages/enseignants/emma.mdx" /* webpackChunkName: "component---src-pages-enseignants-emma-mdx" */),
  "component---src-pages-enseignants-index-mdx": () => import("./../../../src/pages/enseignants/index.mdx" /* webpackChunkName: "component---src-pages-enseignants-index-mdx" */),
  "component---src-pages-enseignants-jivatan-mdx": () => import("./../../../src/pages/enseignants/jivatan.mdx" /* webpackChunkName: "component---src-pages-enseignants-jivatan-mdx" */),
  "component---src-pages-enseignants-nilakanta-mdx": () => import("./../../../src/pages/enseignants/nilakanta.mdx" /* webpackChunkName: "component---src-pages-enseignants-nilakanta-mdx" */),
  "component---src-pages-enseignants-schraddarjuna-mdx": () => import("./../../../src/pages/enseignants/schraddarjuna.mdx" /* webpackChunkName: "component---src-pages-enseignants-schraddarjuna-mdx" */),
  "component---src-pages-enseignants-shantindra-mdx": () => import("./../../../src/pages/enseignants/shantindra.mdx" /* webpackChunkName: "component---src-pages-enseignants-shantindra-mdx" */),
  "component---src-pages-enseignants-vajratay-mdx": () => import("./../../../src/pages/enseignants/vajratay.mdx" /* webpackChunkName: "component---src-pages-enseignants-vajratay-mdx" */),
  "component---src-pages-enseignement-mdx": () => import("./../../../src/pages/enseignement.mdx" /* webpackChunkName: "component---src-pages-enseignement-mdx" */),
  "component---src-pages-evenements-20210411-mdx": () => import("./../../../src/pages/événements/20210411.mdx" /* webpackChunkName: "component---src-pages-evenements-20210411-mdx" */),
  "component---src-pages-evenements-20210501-mdx": () => import("./../../../src/pages/événements/20210501.mdx" /* webpackChunkName: "component---src-pages-evenements-20210501-mdx" */),
  "component---src-pages-evenements-20220402-mdx": () => import("./../../../src/pages/événements/20220402.mdx" /* webpackChunkName: "component---src-pages-evenements-20220402-mdx" */),
  "component---src-pages-evenements-20220526-mdx": () => import("./../../../src/pages/événements/20220526.mdx" /* webpackChunkName: "component---src-pages-evenements-20220526-mdx" */),
  "component---src-pages-evenements-20221028-mdx": () => import("./../../../src/pages/événements/20221028.mdx" /* webpackChunkName: "component---src-pages-evenements-20221028-mdx" */),
  "component---src-pages-evenements-index-mdx": () => import("./../../../src/pages/événements/index.mdx" /* webpackChunkName: "component---src-pages-evenements-index-mdx" */),
  "component---src-pages-formation-cours-en-ligne-mdx": () => import("./../../../src/pages/formation/cours-en-ligne.mdx" /* webpackChunkName: "component---src-pages-formation-cours-en-ligne-mdx" */),
  "component---src-pages-formation-index-mdx": () => import("./../../../src/pages/formation/index.mdx" /* webpackChunkName: "component---src-pages-formation-index-mdx" */),
  "component---src-pages-formation-seminaires-mdx": () => import("./../../../src/pages/formation/seminaires.mdx" /* webpackChunkName: "component---src-pages-formation-seminaires-mdx" */),
  "component---src-pages-images-jsx": () => import("./../../../src/pages/images.jsx" /* webpackChunkName: "component---src-pages-images-jsx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-newsletter-mdx": () => import("./../../../src/pages/newsletter.mdx" /* webpackChunkName: "component---src-pages-newsletter-mdx" */),
  "component---src-pages-sangha-bulle-mdx": () => import("./../../../src/pages/sangha/bulle.mdx" /* webpackChunkName: "component---src-pages-sangha-bulle-mdx" */),
  "component---src-pages-sangha-index-mdx": () => import("./../../../src/pages/sangha/index.mdx" /* webpackChunkName: "component---src-pages-sangha-index-mdx" */),
  "component---src-pages-sangha-neuchatel-mdx": () => import("./../../../src/pages/sangha/neuchatel.mdx" /* webpackChunkName: "component---src-pages-sangha-neuchatel-mdx" */),
  "component---src-pages-teachers-mdx": () => import("./../../../src/pages/teachers.mdx" /* webpackChunkName: "component---src-pages-teachers-mdx" */),
  "component---src-pages-tradition-mahajrya-5-maitres-mdx": () => import("./../../../src/pages/tradition-mahajrya/5-maîtres.mdx" /* webpackChunkName: "component---src-pages-tradition-mahajrya-5-maitres-mdx" */),
  "component---src-pages-tradition-mahajrya-index-mdx": () => import("./../../../src/pages/tradition-mahajrya/index.mdx" /* webpackChunkName: "component---src-pages-tradition-mahajrya-index-mdx" */)
}

