import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

function SearchEngineOptimization ({
  description,
  lang,
  meta,
  title,
  location,
  params,
  data,
  pageContext,
  children
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <>
      <title>{title || site.siteMetadata.title}</title>
      {/* <meta name='description' content={data.page.description} /> */}
      {/* <meta
        name='twitter:url'
        content={`https://www.foobar.tld/${location.pathname}`}
      /> */}
      {/* htmlAttributes={
        {
          // lang,
        }
      } */}
      {/* title={title} */}
      {/* titleTemplate={`%s | ${site.siteMetadata.title}`} */}
      <meta name='description' content={metaDescription} />
      <meta name='og:title' content={title} />
      <meta name='og:description' content={metaDescription} />
      <meta name='og:type' content='website' />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:creator' content={site.siteMetadata.author} />
      <meta name='twitter:description' content={metaDescription} />
      {children}
    </>
  )
}

SearchEngineOptimization.defaultProps = {
  lang: 'en',
  meta: [],
  description: ''
}

SearchEngineOptimization.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SearchEngineOptimization
