import React from 'react'
import { Link } from 'gatsby'

const BackLink = ({ children, link }) => (
  <p className='mt-2 mb-2 font-light'>
    <Link
      className='text-gray-600 hover:text-blue-600 inline-flex items-center'
      to={link}
    >
      <svg
        className='fill-current w-4 h-4 mr-1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width='24'
        height='24'
      >
        <path
          className='heroicon-ui'
          d='M5.41 11H21a1 1 0 0 1 0 2H5.41l5.3 5.3a1 1 0 0 1-1.42 1.4l-7-7a1 1 0 0 1 0-1.4l7-7a1 1 0 0 1 1.42 1.4L5.4 11z'
        />
      </svg>
      {children}
    </Link>
  </p>
)

const BackTeacher = () => (
  <BackLink link='/enseignants/'>Retour aux enseignants</BackLink>
)

const BackHome = () => <BackLink link='/'>Retour à la page d'accueil</BackLink>

export { BackTeacher }

export default BackHome
