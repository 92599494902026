import * as React from 'react'
import { Link } from 'gatsby'

const MenuItem = ({ children, link, isSelected }) => (
  <li className='block mt-4 lg:inline-block lg:mt-4 text-blue-100 hover:text-white mr-4'>
    <Link
      className={isSelected ? 'font-bold' : ''}
      to={link}
    >
      {children}
    </Link>
  </li>
)

export default MenuItem
