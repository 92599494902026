// example of currentPath and targetPath
// - /
// - /enseignants
// - /enseignants/emma
// - /approche

// example of positive match
// - / & /
// - /enseignants/ & /enseignants/emma
// - /enseignants/emma & /enseignants/emma

// example of negative match
// - / & /enseignants
// - /approche/ & /enseignants/

const isInPath = (currentPath, targetPath) => {
  // similar path should pass
  if (currentPath === targetPath) return true

  // other base level should not pass
  if (targetPath === '/') return false

  // child path should pass
  if (currentPath.indexOf(targetPath) >= 0) return true

  // otherwise it should not pass
  return false
}

export default isInPath
