import React, { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import SearchEngineOptimization from '../search-engine-optimization'
import BuddhaLogo from '../../images/svg/buddha.svg'
import MahajryaLogo from '../../images/svg/mahajrya.svg'
import MenuItem from './menu-item'
import ButtonItem from './button-item'
import isInPath from '../../utils/isInPath'
import { isMahajryaDomain } from '../../utils/getDomain'

const menuItems = [
  { path: '/', title: 'Qui sommes-nous ?' },
  /* { path: '/approche', title: 'Notre approche' }, */
  { path: '/enseignants', title: 'Nos enseignants' },
  { path: '/formation', title: 'Nos formations' },
  { path: '/sangha', title: 'La Sangha' },
  { path: '/enseignement', title: "L'enseignement" },
  { path: '/tradition-mahajrya', title: 'La tradition Mahajrya' }
]
const ctaBtn = { path: '/contact', title: 'Contactez-nous' }

const Header = ({ siteTitle, seoTitle, currentPath }) => {
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const menuActiveClass = showMenu ? '' : 'hidden lg:visible'

  return (
    <header className='bg-blue-900 p-4'>
      <div className='container mx-auto px-4'>
        <div className='flex items-center justify-between flex-wrap lg:justify-start'>
          <div className='flex items-center flex-shrink-0 text-white mr-6'>
            {/* <SearchEngineOptimization title={seoTitle || siteTitle} /> */}
            {isMahajryaDomain() ? <MahajryaLogo /> : <BuddhaLogo />}
            <h1 className='text-2xl md:text-2xl mb-2 mt-2 mr-8 ml-4'>
              <Link className='' to='/'>
                {isMahajryaDomain()
                  ? siteTitle
                  : 'Méditation et Intégration Émotionnelle'}
              </Link>
            </h1>
          </div>
          <div className='flex items-center justify-between w-full md:w-auto text-white'>
            <div className='font-normal italic text-base tracking-tight mr-4 lg:mr-0'>
              La conscience est le dissolvant de la souffrance
            </div>
            <div className='lg:hidden'>
              <button
                className=' items-center px-3 py-2 border rounded text-blue-100 border-blue-400 hover:text-white hover:border-white'
                onClick={toggleMenu}
              >
                <svg
                  className='fill-current h-3 w-3'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <title>Menu</title>
                  <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <nav
          className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${menuActiveClass}`}
        >
          <ul className='lg:flex-grow'>
            {menuItems.map(function ({ path, title }, i) {
              return (
                <MenuItem
                  key={i}
                  link={path}
                  isSelected={isInPath(currentPath, path)}
                >
                  {title}
                </MenuItem>
              )
            })}
          </ul>
          <div>
            <ButtonItem
              link={ctaBtn.path}
              isSelected={isInPath(currentPath, ctaBtn.path)}
            >
              {ctaBtn.title}
            </ButtonItem>
          </div>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ''
}

export default Header

export const Head = () => (
  <SearchEngineOptimization>
    {/* <link id="icon" rel="icon" href="icon-specific-for-this-page" /> */}
  </SearchEngineOptimization>
)
