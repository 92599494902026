import React from 'react'
import PropTypes from 'prop-types'

const MenuTitle = (props) => {
  return (
    <div
      {...props}
      className='flex items-center py-2'
    >
      <span className='text-lg text-gray-500 font-bold'>{props.children}</span>
    </div>
  )
}
const MenuSection = (props) => {
  return <div {...props} className='mb-2' />
}

const MenuSectionTitle = (props) => {
  return (
    <div
      {...props}
      className='flex items-center py-2 border-r-4 border-white hover:border-blue-500 hover:bg-blue-100 cursor-pointer'
    >
      <span className='text-gray-500 font-bold'>
        <a href={props.href}>{props.children}</a>
      </span>
    </div>
  )
}

const MenuItem = (props) => {
  return (
    <div {...props} className='mb-2'>
      <div className='text-sm mb-0 text-gray-500'>{props.headline}</div>
      <div className='group flex items-center py-2 pl-2 rounded-sm border-r-4 border-white hover:border-blue-500 hover:bg-blue-100 cursor-pointer'>
        <i className='flex-shrink-0 h-3 w-3 rounded-lg bg-blue-300 group-hover:bg-blue-500 mr-2' />
        <span className='text-base text-gray-500'>
          <a href={props.href}>{props.children}</a>
        </span>
      </div>
    </div>
  )
}

const Sidebar = () => {
  return (
    <nav
      id='side-bar'
      className='absolute top-0 right-0 lg:visible invisible -translate-x-72 lg:-translate-x-0 duration-300 ease-in-out w-72 rounded-xl bg-white shadow-lg py-3 pl-3 pr-2 pt-4'
    >
      <div className='h-full flex flex-col'>
        <MenuTitle>Agenda</MenuTitle>
        <MenuSection>
          <MenuSectionTitle href='/sangha'>
            Les rencontres de la Sangha
          </MenuSectionTitle>

          <MenuItem href='/sangha/bulle' headline='Tous les mardis soir'>
            Rencontres à Bulle
          </MenuItem>

          <MenuItem href='/sangha/neuchatel' headline='Tous les jeudis soir'>
            Rencontres à Neuchâtel
          </MenuItem>
        </MenuSection>

        <MenuSection>
          <MenuSectionTitle href='/formation/cours-en-ligne'>
            Les cours en ligne
          </MenuSectionTitle>

          <MenuItem
            href='/formation/cours-en-ligne'
            headline='Tous les jeudis soir'
          >
            Le Kuji-In
          </MenuItem>
        </MenuSection>

        <MenuSection>
          <MenuSectionTitle href='/formation/seminaires'>
            Les séminaires
          </MenuSectionTitle>

          <MenuItem href='/événements/20220402' headline='2-3 avril 2022'>
            Formation sur les 5 Maîtres
          </MenuItem>

          <MenuItem
            href='/événements/20220526'
            headline='26-29 mai 2022, à Bulle'
          >
            Séminaire de 4 jours sur l'incarnation de l'âme
          </MenuItem>
        </MenuSection>
      </div>
    </nav>
  )
}

Sidebar.propTypes = {
  siteTitle: PropTypes.string
}

Sidebar.defaultProps = {
  siteTitle: ''
}

export default Sidebar
